import React, { useState } from "react";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import { UK_POSTCODE_REGEX } from "../../utils/regex";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import styles from "./Search.module.scss";
import { checkPostcode } from "../../utils/checkPostcode.js";

const Search = ({
  mobileNavShowing,
  showResults,
  businessTheme: { domain, local },
  postcode,
  setPostcode,
  setLoading,
  setAddress,
  setVaildSearch,
}) => {
  const [invaildPostCode, setInvalidPostCode] = useState({
    error: false,
    msg: "Please enter a valid postcode",
  });
  const [loading, setSearchLoading] = useState(false);

  const tracker = useTracker();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!UK_POSTCODE_REGEX.test(postcode)) {
      return setInvalidPostCode({ ...invaildPostCode, error: true });
    }

    tracker.logEvent(".wGck.6C48kIGHpC");

    try {
      setLoading(true);
      setSearchLoading(true);
      const appEngineUrl = local
        ? process.env.REACT_APP_APP_ENGINE_LOCAL
        : process.env.REACT_APP_APP_ENGINE;
      const address = await checkPostcode(appEngineUrl, postcode);

      if (!address || address.length === 0) {
        throw Error;
      }

      setSearchLoading(false);
      setAddress(address);
      showResults(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSearchLoading(false);
      showResults(false);
      setInvalidPostCode({ ...invaildPostCode, error: true });
    }
  };

  return (
    <Trackable loadId="kCUZDe6C48kIGCgO" interfaceId="iI9JDe6C48kIGLFa">
      <div
        className={`${styles.searchContainer} dpd-columns ${
          mobileNavShowing ? styles.moveLeft : styles.revert
        }`}
      >
        <Breadcrumb local={local} domain={domain} />
        <div className={styles.appContent}>
          <div className={styles.module}>
            <div
              className={`${styles.moduleHeading} ${
                local ? styles.moduleHeadingLocal : ""
              }`}
            >
              <h3 className={styles.textHeading}>Depot Finder</h3>
            </div>

            {loading ? (
              <div className={styles.spinnerContainer}>
                <img
                  alt="spinner"
                  src={
                    local ? "assets/dpdlocal-spinner.gif" : "assets/spinner.gif"
                  }
                />
              </div>
            ) : (
              <div className={styles.moduleBody}>
                <div
                  id="message_panel"
                  className={`${invaildPostCode.error && styles.errorPanel}`}
                >
                  {invaildPostCode.error && <div>{invaildPostCode.msg}</div>}
                </div>
                <div className={styles.formHeader}>
                  <label htmlFor="postcode">
                    Please show me the local depot for the following postcode:
                  </label>
                </div>
                <form id="find_depot" method="post" onSubmit={handleSubmit}>
                  <div className={styles.formBody}>
                    <div className={`dpd-row ${styles.formRow}`}>
                      <div
                        className={`dpd-column ${styles.formCol1} small-6 mini-6 medium-6 large-4 tight-left`}
                      >
                        <label className={styles.lable} htmlFor="postcode">
                          Your Postcode
                        </label>
                      </div>
                      <div
                        className={`dpd-column ${styles.formCol2} small-6 mini-6 medium-4 large-2 tight-right`}
                      >
                        <input
                          className={styles.formInput}
                          type="text"
                          id="postcode"
                          maxLength="8"
                          value={postcode}
                          onChange={(e) => {
                            tracker.logEvent("I5lIk.6C48kIGHoS");
                            setPostcode(e.target.value);
                          }}
                          disabled={mobileNavShowing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`dpd-row ${styles.actionBar}`}>
                    <div className={`dpd-column ${styles.actionCol}`}>
                      <button
                        type="submit"
                        id="submit_button"
                        className={`${styles.button} blue button small right ${
                          local ? styles.buttonLocal : ""
                        }`}
                        disabled={mobileNavShowing}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Trackable>
  );
};

export default Search;
